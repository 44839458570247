import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    animationAll:{
      card:false,
      main_content_top:false,
      category_left:false,
      info_card:false,
      brand_top_msg:false,
      brand_attestation_list:false,
      swiper_img_list:false,
      enterprise_center_container:false,
      form:false,
      franchise_right_msg:false,
    }
  },
  getters:{
    homeCard: state => state.animationAll.card,
    homeContentTop: state => state.animationAll.main_content_top,
    categoryRight: state => state.animationAll.category_left ,
    infoCard: state => state.animationAll.info_card,
    brand_top_msg: state => state.animationAll.brand_top_msg,
    brand_attestation_list: state => state.animationAll.brand_attestation_list,
    swiper_img_list: state => state.animationAll.swiper_img_list,
    enterprise_center_container: state => state.animationAll.enterprise_center_container,
    form: state => state.animationAll.form,
    franchise_right_msg: state => state.animationAll.franchise_right_msg,
  },
  mutations: {
    openAnimationAll(state,attribute){
      state.animationAll[attribute] = true
    },
    closeAnimationAll(state,attribute){
      state.animationAll[attribute] = false
    }
  },
  actions: {
  },
  modules: {
  }
})
