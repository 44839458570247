<template>
  <div class="home">
    <Header></Header>
    <!-- 轮播图开始 -->
    <div class="home_banner">
      <swiper :options="swiperOptions" style="height:700px;" class="mySwiper">
        <swiper-slide>
          <div class="swiper_side_content swiper_side_first">
            <div class="website_wrapper side_content_container">
              <div class="side_module">
                <div class="module_title">智慧医疗，引领未来</div>
                <div class="module_en_title">leading the future</div>
                <div class="module_message">
                  <div>当医疗与科技的力量相互交融，创新便呼之欲出</div>
                  <div>当我们不断地寻求突破，探索未知，将为人类健康事业带来全新的可能和机遇</div>
                </div>
                <div class="module_more" @click="moreBrand">查看更多</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper_side_content swiper_side_second">
            <div class="website_wrapper side_content_container">
              <div class="side_module_second">
                <div class="side_second_title">成为大健康行业佼佼者</div>
                <div class="side_second_en_title">Becoming a leader in the big health industry</div>
                <div class="side_second_msg">在国家“互联网+医疗健康“制度的指引下,持续推动技术创新和产品升级，秉承“用科技服务人类健康”的理念，推动世界医疗健康事业发展</div>
                <div class="side_second_bg"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next1" slot="button-next"></div>
      <div class="swiper-button-prev1" slot="button-prev"></div>
    </div>
    <!-- 轮播图结束 -->

    <!-- 主体内容开始 -->
    <div class="home_main main_bg">
      <div class="main_content website_wrapper">
        <card-list></card-list>
        <div class="category">
          <Category></Category>
        </div>
      </div>
    </div>
    <div class="home_main btm_home_main">
      <div class="main_content_second website_wrapper">
        <div ref="animation"
             :class="['main_content_top', homeContentTop ? 'animation_top_start' : 'animation_opacity']">
          <div class="content_top_title">为医院高质量发展保驾护航</div>
          <div class="line">—</div>
          <div class="contnet_top_msg">
            这是一种基于数字化技术与医疗健康领域的深度融合。随着科技不断进步，数字化转型已经成为推动医疗行业变革的重要力量。而通过互联网技术，医疗服务可以更加普惠、高效、便捷地提供给全球范围内的患者和医护人员。
            同时，这也为未来医疗发展带来了更多无限的可能性，岳泰兴医疗将不断探索和创新，为人类健康事业贡献更大的力量
          </div>
        </div>
        <div class="main_info_list">
          <div v-for="(item, index) in infoList" :key="index">
            <InfoCard :cardInfo="item"></InfoCard>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体内容结束 -->
    <Footer></Footer>
  </div>
</template>

<script>
import InfoCard from '@/components/InfoCard'
import CardList from '@/components/CardList'
import Category from '@/components/Category'
import {swiper, swiperSlide} from "vue-awesome-swiper";
import Header from '@/components/common/Header';
import Footer from '@/components/common/Footer';
import "swiper/css/swiper.css";
import {mixins} from '@/mixin';

export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
    CardList,
    Category,
    InfoCard,
    Header,
    Footer
  },
  mixins: [mixins],
  data() {
    return {
      flag: false,
      swiperOptions: {
        loop: true,//无限循环
        effect: "fade", //渐变切换 ，切换类型
        speed: 2200,
        // autoplay: {
        //   //自动切换
        //   delay: 5000,
        //   stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
        //   disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
        // },
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination1",//若改变分页样式，需改变样式名字，默认为.swiper-pagination
        //   type: "bullets",
        //   clickable: true, //允许分页点击跳转
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next1",//若改变右箭头样式，需改变名字，默认为.swiper-button-next
          prevEl: ".swiper-button-prev1",//若改变左箭头样式，需改变名字，默认为.swiper-button-prev
          clickable: true,
        },
      },
      //数据列表
      slideList: [
        {
          detail: "第一页",
          img: require('@/assets/img/home/swiper1.jpg')
        },
        {
          detail: "第二页",
          img: require('@/assets/img/home/swiper2.jpg')
        }
      ],
      infoList: [
        {
          infoPic: require('../assets/img/card/index_card_1.jpg'),
          order: 'A',
          title: '互联网医院',
          message: `互联网医院是建立以医院为主体的互联网医疗服务平台，构建“线上预诊一线下面诊与治疗一线上复诊”的新型医疗模式。提供云诊室，方便医生在院外不同环境中利用互联网医院平台进行诊疗服务;建设面向患者的移动互联网服务平台，针对慢性病常见病患者提供更方便的就医服务;建设与医共(联)体成员单位互联互通的信息平台，实现分级诊疗、专家资源下沉、技术远程指导、远程教育等业务的开展和协同，最终实现医院信息各级系统由院内局域网向院外广域延伸，通过互联网化的流程改造，为院内信息系统的全面升级奠定基础。`,
          moreUrl: '',
          reverse: false,
          more:true,
        }, {
          infoPic: require('../assets/img/card/index_card_2.jpg'),
          order: 'B',
          title: '智慧医院',
          message: `岳泰兴智慧医院是面向医院信息化的全面解决方案，智慧医院以实现自动化医疗为核心，打造院内精细化管理为导向的医院智慧管理系统、以实际数据为宗旨的决策支持系统.岳泰兴智慧医院全面助力医院提升管理与决策水平，和医院一起为人类做出一份贡献。`,
          moreUrl: '',
          reverse: true,
          more:true,
        }, {
          infoPic: require('../assets/img/card/index_card_3.jpg'),
          order: 'C',
          title: '智慧养老',
          message: `智慧养老平台利用“医养一体化”的发展模式，集医疗、康复、养生、养老等为一体化，打造机构养老与居家养老、生活照料与康复关怀相融合的全新智慧养老服务平台,最终实现管理机制创新、服务模式转变、信息互通、资源共享、服务协同，使医疗卫生、养老服务事业全面提升，卫生改革目标全面实现，人类健康水平全面提高。基本实现卫生政务电子化、医疗服务数字化，公共卫生管理网络化，养老服务管理信息化、智能化、安全保障一体化的目标。`,
          moreUrl: '',
          reverse: false,
          more:true,
        }
      ]
    }
  },
  methods: {
    moreBrand() {
    //   const routeUrl = this.$router.resolve({
    //     path: "/newMore",
    //     // query: {id:66},
    //   });
    //   window.open(routeUrl.href, "_blank");
      this.$router.push('/newMore');
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  .home_banner {
    position: relative;
    height: 700px;

    .mySwiper {
      height: 700px;

      img {
        height: 100%;
        width: 100%;
      }

      .swiper_side_first {
        background: url('../assets/img/home/swiper1.jpg');
      }

      .swiper_side_second {
        background: url('../assets/img/home/swiper2.jpg');
      }

      .swiper_side_content {
        position: relative;
        height: 700px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: default;

        .side_content_container {
          height: 700px;
          position: relative;

          .side_module {
            position: absolute;
            top: 178px;
            left: 0px;
            width: 576px;
            height: auto;
            color: #ffffff;

            .module_title {
              font: 60px '幼圆';
            }

            .module_en_title {
              font: 61px '微软雅黑';
              font-weight: 700;
            }

            .module_message {
              height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 14px;
              margin: 35px 0;
            }

            .module_more {
              margin-top: 60px;
              color: #02b2b5;
              background: #ffffff;
              width: 162px;
              height: 50px;
              text-align: center;
              line-height: 50px;
              cursor: pointer;
            }
          }

          .side_module_second {
            position: absolute;
            top: 193px;
            left: 0px;
            width: 488px;
            height: auto;
            font-family: '微软雅黑';

            .side_second_title {
              font-size: 50px;
              color: #02b2b5;
              font-weight: 700;
            }

            .side_second_en_title {
              color: #7c7c7c;
              font-size: 36px;
              margin: 25px 0 10px 0;
            }

            .side_second_msg {
              font-size: 14px;
              color: #888888;
              width: 400px;
              margin-top: 20px;
            }

            .side_second_bg {
              position: absolute;
              top: 50px;
              left: 420px;
              width: 243px;
              height: 289px;
              background: url('../assets/img/home/side2.png') 100% 100%;
            }
          }
        }
      }
    }

    .swiper-button-next1,
    .swiper-button-prev1 {
      z-index: 99;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 71px;
      height: 71px;
      cursor: pointer;
      border-radius: 5px;
    }

    .swiper-button-next1:hover {
      background: url(../assets/img/home/right.png) no-repeat rgba(0, 0, 0, .3);

    }

    .swiper-button-prev1:hover {
      background: url(../assets/img/home/left.png) no-repeat rgba(0, 0, 0, .3);

    }

    .swiper-button-prev1 {
      left: 40px;
      background: url(../assets/img/home/left.png) no-repeat rgba(0, 0, 0, .1);
    }

    .swiper-button-next1 {
      right: 40px;
      background: url(../assets/img/home/right.png) no-repeat rgba(0, 0, 0, .1);
    }
  }

  .main_bg {
    background: url('../assets/img/home/bg.jpg') 100% no-repeat;
    background-position: 0 100%;
    height: 1300px;
  }
  .btm_home_main{
    height: 2000px;
  }

  .home_main {
    .main_content {
      box-sizing: border-box;
      padding: 50px 0;
      height: 1540px;
      position: relative;

      .category {
        position: absolute;
        top: 304px;
        left: 3px;
        width: 1200px;
        height: auto;
      }
    }

    .main_content_second {
      position: relative;
      box-sizing: border-box;
      height: 1930px;

      .main_content_top {
        position: absolute;
        text-align: center;
        top: 26px;
        left: 211px;
        width: 794px;
        height: auto;
        color: $font_light_color;

        .content_top_title {
          font-size: 30px;
        }

        .line {
          font-size: 40px;
          font-weight: 700;
        }

        .contnet_top_msg {
          color: #b4b4b4;
          font-size: 14px;
          line-height: 30px;
        }
      }

      .main_info_list {
        position: absolute;
        top: 300px;
        left: 0px;
        width: 1200px;
      }
    }
  }
}
</style>
