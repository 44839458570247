import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单跳转当前页面报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
    // component: resolve => require([Home], resolve)
  }, {
    path: '/enterprise',
    name: 'enterprise',
    component: resolve => require(['@/views/Enterprise.vue'], resolve)
    // component: () => import(/* webpackChunkName: "about" */ '@/views/Enterprise.vue')
  },{
    path:'/franchise',
    name:'franchise',
    component: resolve => require(['@/views/Franchise.vue'], resolve)
    // component: () => import('@/views/Franchise.vue')
  },{
    path:'/brand',
    name:'brand',
    component: resolve => require(['@/views/Brand.vue'], resolve)
    // component: () => import('@/views/Brand.vue')
  },{
    path:'/about',
    name:'about',

    component: resolve => require(['@/views/About.vue'], resolve)
    // component: () => import('@/views/About.vue')

  },{
    path:'/infoDetail',
    name:'infoDetail',
    component: resolve => require(['@/views/InfomationDetail.vue'], resolve)
    // component: () => import('@/views/InfomationDetail.vue')
  },
  {
    path:'/newMore',
    name:'newMore',
    component: resolve => require(['@/views/newMore.vue'], resolve)
    // component: () => import('@/views/newMore.vue')
  },
  {
    path: '/contactUs',
    name:'contactUs',
    component: resolve => require(['@/components/contactUs.vue'], resolve)
    // component: () => import('@/components/contactUs.vue')
  },
  {
    path: '/recruit',
    name:'recruit',
    component: resolve => require(['@/views/recruitment.vue'], resolve)
    // component: () => import('@/components/contactUs.vue')
  },
  {
    path: '/recruitInfo',
    name:'recruitInfo',
    component: resolve => require(['@/components/recruitInfo/index'], resolve)
    // component: () => import('@/components/contactUs.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
